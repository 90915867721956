<template>
	<div class="cover">
		<div class="a4 ql-editor texta4" ref="print" style="padding: 50px 100px;position: relative;">
			<div class="mytableinner">
				<div style="font-size: 26px;text-align: center;">
					中国人民政治协商会议西宁市委员会
				</div>
				<div class="blxtitle" style="font-size: 20px;text-align: center;">
					{{formData.sshy}}
				</div>
				<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-top: 20px;">
					<div style="font-size: 16px;">
						提案号({{formData.code?formData.code:''}})
					</div>
					<div style="font-size: 16px;" @click="showgetdate = true">
						类别（{{formData.lei_name}}）
					</div>
					<div style="font-size: 16px;position: relative;" @click="showdate=true">
						{{formData.rq}}
					</div>
				</div>
				<div class="tablebox">
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;">
							题目
						</div>
						<div class="value" style="width: 85%;position: relative;padding: 0;font-size: 16px;">
							{{formData.name}}
						</div>
					</div>
					<div class="autoheight" style="border: none;height: auto;min-height: 20px;display: flex;">
						<div class="namebox autoheight erjititle"
							style="width: 32%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;border-right: none;">
							提案形式
						</div>
						<div class="namebox autoheight"
							style="width: 68%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-bottom: none;font-size: 14px;">
							{{formData.taxs}}
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;flex-direction: column;height: fit-content;letter-spacing: 2px;">
							<p>委</p>
							<p>员</p>
							<p>提</p>
							<p>案</p>
						</div>
						<div style="width: 85%;position: relative;padding: 0;">
							<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 40px;">
									提案者
								</div>
								<div class="value autoheight" style="width: 25%;padding: 0;min-height: 40px;">
									{{formData.taz}}
								</div>
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 40px;">
									工作单位及职务
								</div>
								<div class="value autoheight" style="width: 35%;padding: 0;min-height: 40px;">
									{{formData.gzdwjzw}}
								</div>
							</div>
							<div style="width: 100%;display: flex;">
								<div class="namebox autoheight canlclefb erjititle"
									style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height:40px;">
									联系方式
								</div>
								<div style="width: 80%;display: flex;flex-direction: column;">
									<div style="width: 100%;display: flex;border-bottom: 1px solid rgb(0,128,0);">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 40px;">
											手机号码
										</div>
										<div class="value autoheight" style="width: 25%;padding: 0;min-height: 40px;word-break: break-all;">
											{{formData.sjhm}}
										</div>
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 40px;">
											电子邮箱
										</div>
										<div class="value autoheight" style="width: 35%;padding: 0;min-height: 40px;word-break: break-all;">
											{{formData.dzyx}}
										</div>
									</div>
									<div style="display: flex;">
										<div class="namebox autoheight canlclefb erjititle"
											style="width: 20%;border-left: 1px solid rgb(0,128,0);min-height: 40px;">
											通讯地址
										</div>
										<div class="value autoheight" style="width: 80%;padding: 0;min-height: 40px;word-break: break-all;">
											{{formData.txdz}}
										</div>
									</div>
								</div>
							</div>
				
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;;min-height: 20px;">
						<div class="namebox" style="width: 15%;height: fit-content;letter-spacing: 2px;flex-direction: column;">
							<p>集</p>
							<p>体</p>
							<p>提</p>
							<p>案</p>
						</div>
						<div style="width: 85%;position: relative;padding: 0;display: flex;">
							<div class="canlclefb"
								style="width: 40%;display: flex;border-left: 1px solid rgb(0,128,0);font-size: 12px;flex-direction: column;">
								<div class="erjititle">
									提案单位(加盖公章)
								</div>
								<div v-if="formData.gz[0]" style="width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 10px;">
									<img :src="formData.gz[0].fullurl" style="width: 70%;"></img>
								</div>
				
							</div>
							<div class="autoheight" style="width: 60%;display: flex;flex-direction: column;border: none;">
								<div class="autoheight" style="width: 100%;display: flex;border: none;;min-height: 60px;">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										负责人<br>(签名)
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
										{{formData.fzrqm}}
									</div>
								</div>
								<div class="autoheight"
									style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										单位及<br>职务
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
										{{formData.dwjzw}}
									</div>
								</div>
								<div class="autoheight"
									style="width: 100%;display: flex;border: none;;min-height: 60px;border-top: 1px solid rgb(0,128,0);">
									<div class="namebox autoheight canlclefb erjititle"
										style="width: 30%;border-left: 1px solid rgb(0,128,0);min-height: 60px;">
										联系电话
									</div>
									<div class="value autoheight" style="width: 70%;padding: 0;min-height: 60px;word-break: break-all;">
										{{formData.lxdh}}
									</div>
								</div>
							</div>
				
						</div>
					</div>
					<div class="line" style="height: auto;">
						<div class="namebox" style="width: 100%;">
							联名提案栏
						</div>
					</div>
					<div class="line"  style="border-bottom: none;height: auto;">
						<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
							联名人(签名)
						</div>
						<div class="namebox erjititle" style="width: 40%;font-weight: 400;font-size: 12px;">
							工作单位及职务
						</div>
						<div class="namebox erjititle" style="width: 30%;font-weight: 400;font-size: 12px;">
							联系电话
						</div>
					</div>
									
					<div v-for="(item,index) in formData.lmusers_name" class="autoheight lmtlist"
						style="border: none;height: auto;min-height: 20px;display: flex;">
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;">
							<div style="font-size: 14px;">
								{{item.name}}
							</div>
						</div>
						<div class="namebox autoheight"
							style="width: 40%;font-weight: 400;border: 1px solid rgb(0,128,0);min-height: 20px;border-right: none;border-bottom: none;font-size: 14px;">
									
						</div>
						<div class="namebox autoheight"
							style="width: 30%;font-weight: 400;min-height: 20px;border: 1px solid rgb(0,128,0);border-bottom: none;font-size: 14px;">
							{{item.phone}}
						</div>
					</div>
					<div class="line autoheight" style="border-bottom: none;">
						<div class="namebox" style="width: 15%;display: block;">
							审查
							<p>意见</p>
						</div>
						<div class="value"
							style="justify-content: space-between;align-items: center;text-align: left;flex-direction: column;width: 85%;min-height: 150px;padding: 0;">
							
							<div style="width: 100%;display: flex;align-items: center;min-height: 150px;">
								
								<div style="width: 50%;min-height: 150px;padding: 10px;border-right: 1px solid rgb(0,128,0);">
									
									<div v-if="formData.bylaly" style="width: 100%;text-align: left;">{{formData.bylaly}}</div>
									<div v-if="formData.scyj" style="width: 100%;text-align: left;">{{formData.scyj}}</div>
								</div>
								<div style="padding: 10px;width: 50%;min-height: 150px;display: flex;align-items: center;justify-content: space-between;flex-direction: column;">
									
									<div style="width: 100%;">
										<img v-if="lian_zhang" :src="lian_zhang" style="width: 50%;max-height: 100px;"></img>
									</div>
									
									<div style="width: 100%;text-align: right;">
										{{formData.scyjrq}}
									</div>
								</div>
							</div>
							
						</div>
					</div>
					<div class="line autoheight">
						<div class="namebox" style="width: 15%;display: block;">
							办理
							<p>单位</p>
						</div>
						<div class="autoheight" style="width: 85%;display: flex;border: none;min-height: 150px;">
							<div class="value autoheight"
								style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 40%;flex-direction: column;">
								<div class="erjititle" style="font-size: 12px;width: 100%;">
									(主办单位)
								</div>
								<div>
									{{formData.blusers_name}}
								</div>
				
							</div>
							<div class="value autoheight"
								style="justify-content: flex-start;align-items: flex-start;text-align: left;width: 60%;flex-direction: column;">
								<div class="erjititle erjititle" style="font-size: 12px;width: 100%;">
									(协办单位)
								</div>
								<div>
									{{formData.fbusers_name}}
								</div>
							</div>
						</div>
					</div>
					
				</div>
				<div style="color: rgb(0,128,0);font-size: 12px;">
					注意事项：① 委员个人提案和联名提案分别在各自栏中填写信息；② 集体提案是指民主党派、工商联、人民团体、政协专门委员会和政协全体会议期间的界别、委员小组提出的提案；③委员联名提出提案，发起人作为第一提案者，其他联名人须在“联名提案栏”签名；④提案内容务必一事一案，字数控制在2000字以内，力求做到“切口小、道理硬、靶向准、建议实”。
				</div>
			</div>
			
			
			<div class="page" style="page-break-after: always;"></div>
			<div style="width:100%;margin-top: 80px;"></div>
			<div style="font-size: 30px;text-align: center;margin-top: 5px;font-size: 29px;font-family: 'xiaobiaosongti';font-weight: bold;">{{formData.name}}</div>
			<div style="font-size:18px;text-align: center;margin-top:10px;font-weight: bold;font-family: 'xiaobiaosongti';">提案者：{{formData.user_name[0].name?formData.user_name[0].name:''}}</div>
			
			<div class="zwfontsize" style="font-family: fangsong;margin-top: 10px;margin-top:50px;">
				<span style="font-weight: bold;">案由分析：</span>
				<p class="myhtml" v-html="formData.content" style="text-indent: 2em;"></p>
			</div>
			
			<div class="zwfontsize" style="font-family: fangsong;margin-top: 50px;">
				<span style="font-weight: bold;">建议办法：</span>
				<p class="myhtml" v-html="formData.way" style="text-indent: 2em;"></p>
			</div>			
			<!-- 
			<div style="margin-top: 60px;display: flex;">
				<span>图片相关：</span>
				<div style="display: flex;align-items: center;flex-wrap: wrap;flex-direction: column;">
						<div class="box_img" v-for="(item,index) in formData.img" :key="index" style="margin: 10px;">
							<img :src="item.fullurl" alt="" style="width: 150px;">					
						</div>  
				</div>
			</div>
			 -->
			<div style="margin-top: 60px;display: flex;">
				<span style="color: #888;" v-show="formData.fujian.length>0">附件列表：</span>
				<div style="display: flex;align-items: center;flex-wrap: wrap;flex-direction: column;width: 80%;">
						<div class="box_img" v-for="(item,index) in formData.fujian" :key="index" style="margin: 10px;display: flex;align-items: center;justify-content: space-between;width: 100%;">
							<span style="color: #888;font-size: 14px;">{{item.filename}}</span>
							<el-button type="success" size="mini" @click="open(item.fullurl)"> 下载查看附件 </el-button>
						</div>  
				</div>
			</div>
			
			
			<div style="margin-top: 60px;display: flex;color: red;" v-if="showbohui">
				<span>驳回理由：</span>
				<div style="">
					{{this.formData.bh_content}}
				</div>
			</div>			
		</div>
		<div style="display: flex;flex-direction: column;margin-left: 30px;">
			<slot name="otherbtn">
			    
			</slot>
			<el-button v-if="showdy" type="success" size="small" @click.stop="getword" style="margin-bottom:10px;margin-left: 10px;">导出word</el-button>
			</el-button>
			<el-button v-if="showdy" type="success" size="small" @click.stop="print" style="margin-bottom:10px;margin-left: 10px;">打印</el-button>
			</el-button>
			<el-button type="primary" size="small" @click.stop="closeyl">关闭详情</el-button>
			</el-button>			
		</div>
	</div>
</template>

<script>
	import { exportWord, getBase64Sync, getBase } from "@/utils/getword.js";

	export default{
		// props:['id','requsturl',"type",'ys_id',"showbohui"],
		props:{
			id:{
				type:String,
				default:""
			},
			requsturl:{
				type:String,
				default:""
			},
			type:{
				type:String,
				default:""
			},
			ys_id:{
				type:String,
				default:""
			},
			ys_id:{
				type:String,
				default:""
			},
			showbohui:{
				type:Boolean,
				default:false
			},
			showdy:{
				type:Boolean,
				default:true
			}
		},
		data(){
			return{
				imaglist:[],
				fileList:[],
				formData:'',
				yjdw:'',
				yj:'',
				lian_zhang:''
			}
		},
		created() {
			
			this.$post({
				url:this.requsturl,
				params:{
					id:this.id
				}
			}).then((res)=>{
				this.formData = res
				
				//判断是否立案  获取立案章
				if(res.type==3){//不立案
					this.$post({
						url:'/api/login/blaz',
						params:{}
					}).then((resss)=>{
						this.lian_zhang = resss.z?resss.z.fullurl:''
						this.formData.lian_zhangbase64 = resss.z.fullur_basel?resss.z.fullur_basel:''
					})
				}else{
					if(res.scyj){
						this.$post({
							url:'/api/login/laz',
							params:{}
						}).then((resss)=>{
							this.lian_zhang = resss.z?resss.z.fullurl:''
							this.formData.lian_zhangbase64 = resss.z.fullur_basel?resss.z.fullur_basel:''
						})
					}
				}
				
			})
			
			
			if(this.type=='sck'){
				this.$axios
				  .post('/admin/proposalRegister/idea_details', { id:this.ys_id?this.ys_id:this.id })
				  .then((res) => {
					this.yjdw = res.data.data.unit_name
					this.yj = res.data.data.idea
					
				  })
				  .catch((err) => err);
				
			}
		},
		methods:{
			async exportWordTest() {	
				let data = JSON.parse(JSON.stringify(this.formData))
				data.wordgz = data.gz.length>=1?data.gz[0].fullurlbase64:''
				data.wordscyjqm = data.shqm.length>=1?data.shqm[0].fullurlbase64:''
				data.tian_user = data.taz?data.taz:data.user_name[0].name
				data.scyjrq = data.scyjrq?data.scyjrq:''
				data.scyj = data.scyj?data.scyj:''
				data.bylaly = data.bylaly?data.bylaly:''
				data.showfj = data.fujian.length>=1?'附件列表：':''
				const imgSize={
					wordgz:[50,50],
					wordscyjqm:[100,50]
				}
			 	exportWord("../static/test.docx", data, data.name+".docx",imgSize);
			},
			getword(){
				this.exportWordTest()
				// this.$axios
				//   .get('http://czxapi.zhihuiqinghai.com/upload/20230309/47710ab1db3e59b204481dcd2f08add1.jpg')
				//   .then((res) => {
					
				//   })
				//   .catch((err) => err);
			},
			open(url){
				window.open(url)
			},
			print(){
				this.$printPage(this.$refs.print)
			},
			closeyl(){
				this.$emit("closedetail")
			}
		}
	}
</script>

<style lang="scss" scoped>
	@font-face {
	  font-family: 'xiaobiaosongti'; /* 为你的字体定义一个名称 */
	  src: url('../assets/fzxbstjt.ttf') format('truetype'); /* 指定字体文件的路径和格式 */
	}
	.cover {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		width: 100vw;
		height: 100vh;
		overflow-y: auto;
		background-color: rgba(0, 0, 0, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		.a4 {
			height: 100%;
			width: 210mm;
			background-color: #fff;
	
			::v-deep h1 {
				font-size: 30px !important;
			}
		}
	
		.texta4 {
			min-height: 95%;
		}
	}
	
	.zwfontsize {
		font-size: 22px;
	}
	
	.mytable {
		width: 100%;
		margin-top: 50px;
	
		.table_title span {
			text-align: center;
			font-weight: 400;
			font-size: 22px;
			font-family: fangsong;
			width: 20%;
		}
	
		.table_title span:nth-child(2) {
			width: 40%;
		}
	
		div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: center;
	
			span {
				border: 1px solid #000000;
			}
	
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				border-right: none;
				border-bottom: none;
			}
	
			span:nth-child(4) {
				border-bottom: none;
			}
		}
	
		div:nth-last-child(1) {
	
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				border-right: none;
				border-bottom: 1px solid #000000;
			}
	
			span:nth-child(4) {
				border-bottom: 1px solid #000000;
			}
		}
	
		.table_cont span {
			text-align: center;
			font-family: fangsong;
			font-weight: 400;
			font-size: 18px;
			width: 20%;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	
		.table_cont span:nth-child(2) {
			width: 40%;
		}
	}
	
	::v-deep h2 span {
		font-size: 26px;
	}
	
	::v-deep h1 strong {
		font-size: 32px;
	}
	
	.yjzx_table {
		margin-top: 30px;
		font-family: fangsong;
		font-size: 18px;
		width: 100%;
		border: 1px solid #000;
		border-collapse: collapse;
	
		td {
			border: 1px solid #000;
			height: 45px;
			padding: 4px 5px;
			text-align: center;
		}
	}
	
	.yuanitem {
		position: relative;
	}
	
	.dagou:before {
		position: absolute;
		content: '';
		height: 15px;
		width: 27px;
		border-left: 2px solid #000;
		border-bottom: 2px solid #000;
		transform: rotate(-45deg);
	}
	
	
	
	::v-deep .el-form-item{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	::v-deep .el-form-item__label{
		width: fit-content !important;
	}
	::v-deep .el-form-item__content{
		margin-left: 0px !important;
	}
	::v-deep .el-input{
		width: 9rem;
	}
	::v-deep .el-input > .el-input__inner{
		min-width: auto;		
	}
	::v-deep .mysearchinput .el-input__inner{
		min-width: auto;
	}
	.mysearchtitile{
		font-size: 14px;
		color: #606266;
		font-weight: 700;
	}
	::v-deep  .el-date-editor.el-input__inner{
		width: 415px;
	}
	
	.line {
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
	}
	
	.namebox {
		height: 100%;
		width: 12%;
		border: 1px solid rgb(0, 128, 0);
		padding: 5px 2px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: bold;
	
		text {
			width: 100%;
		}
	}
	
	.namebox:nth-last-child(1) {
		border-right: 1px solid rgb(0, 128, 0);
	}
	
	.value {
		height: 100%;
		width: 35%;
		padding: 5px;
		text-align: center;
		border: 1px solid rgb(0, 128, 0);
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		font-size: 14px;
	
		text {
			width: 100%;
	
		}
	}
	
	.value:nth-child(2) {
		border-right: none;
	}
	
	.line:nth-last-child(1) {
		border-bottom: 1px solid rgb(0, 128, 0);
	}
	
	.autoheight {
		min-height: 60px;
		height: auto;
		border: 1px solid rgb(0, 128, 0);
	
		.namebox {
			width: 25%;
			border: none;
		}
	
		.value {
			min-height: 60px;
			width: 75%;
			border: none;
			border-left: 1px solid rgb(0, 128, 0);
		}
	}
	
	.canlclefb {
		font-weight: 400;
	}
	
	::v-deep .el-input__inner {
		font-size: 16px;
		color: rgb(0, 128, 0) !important;
	}
	
	.erjititle {
		font-size: 16px !important;
	}
	.mytableinner{
		color: rgb(0, 128, 0);
	}
	.lmtlist:nth-last-child(1){
		border-bottom: 1px solid rgb(0,128,0) !important;
	}
	@media print {
		
	}
</style>